import React, { useState, useEffect } from "react";
import "./App.css"; // Assuming you use CSS for styling similar to your PowerShell's HTML layout
import { Tooltip } from "react-tooltip";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";

const App = () => {
  const [result, setResult] = useState();
  const [currentJob, setCurrentJob] = useState()
  const [loading, setLoading] = useState(true);
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((prevCount) => prevCount + 1); // Increment the count
    }, 60000);

    // Step 3: Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, [ticker]);

  useEffect(() => {
    if (result?.usdPrice) {
      document.title = `IO Totals - ${result.usdPrice}`;
    }
  }, [result]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data for the block rewards summary
        const currentResult = await fetch(
          `https://api.io.mlot.is/block-rewards`
        ).then((res) => res.json());

        if(currentResult.detailed.is_working){
          const jobs = JSON.parse(currentResult.detailed.jobs)
          setCurrentJob(jobs.sort((a,b) => new Date(b.start_time) - new Date(a.start_time))[0])
        }
        setResult(currentResult);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [ticker]);

  const stripDate = (str) => {
    const regex = /UTC\s(\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2})/;
    const match = str.match(regex);
    return new Date(match[1] + "Z").toLocaleString();
  };

  return !loading ? (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <h1 data-tooltip-id="tdate">IO Totals - ${result.usdPrice}</h1>
        <Tooltip id="tdate" style={{ zIndex: 99 }}>
          <p style={{ fontSize: "1rem" }}>
            {new Date(result.token * 1000).toLocaleString()}
          </p>
        </Tooltip>
      </div>

      <div className="container">
        <div className="card-container">
          <div className="card first-card">
            <h5>{result.latestEntry.date}</h5>
            <h2>Last Hour</h2>
            <p>
              <span data-tooltip-id="lastHour">
                ${result.latestEntry.lastHour.usd.toFixed(2)} (
                {result.latestEntry.lastHour.io.toFixed(3)})
              </span>
              <span style={{ float: "right" }} data-tooltip-id="status">
                {result.status.rules[5].check ? (
                  <FaCheckCircle
                    color={
                      result.detailed.is_working ? "royalblue" : "darkgreen"
                    }
                  />
                ) : (
                  <MdError />
                )}
              </span>
            </p>
            <Tooltip id="lastHour">
              <p style={{ fontSize: "1rem" }}>
                Daily: ${(result.latestEntry.lastHour.usd * 24).toFixed(2)}
              </p>
              <p style={{ fontSize: "1rem" }}>
                Weekly: ${(result.latestEntry.lastHour.usd * 24 * 7).toFixed(2)}
              </p>
              <p style={{ fontSize: "1rem" }}>
                Monthly: $
                {(result.latestEntry.lastHour.usd * 24 * (365 / 12)).toFixed(2)}
              </p>
              <p style={{ fontSize: "1rem" }}>
                Yearly: $
                {(result.latestEntry.lastHour.usd * 24 * 365).toFixed(2)}
              </p>
            </Tooltip>
            <Tooltip id="status" style={{ zIndex: 100 }}>
              <p style={{ fontSize: "1rem" }}>
                {result.detailed.is_working ? (
                  <>
                    <p style={{ fontSize: "1rem" }}>
                      Start Time: 
                      {new Date(currentJob.start_time + 'Z').toLocaleString()}
                    </p>
                    <p style={{ fontSize: "1rem" }}>
                      End Time:
                      {new Date(currentJob.end_time + 'Z').toLocaleString()}
                    </p>
                    <p style={{ fontSize: "1rem" }}>
                      Hire Rate: {`${currentJob.total_hire_rate.toFixed(2)} io ($${(currentJob.total_hire_rate * result.usdPrice).toFixed(2)})`}
                    </p>
                  </>
                ) : (
                  stripDate(result.status.rules[5].reason + "Z")
                )}
              </p>
            </Tooltip>
          </div>
          <div className="card first-card">
            <h5>{new Date().toLocaleString("default", { month: "long" })}</h5>
            <h2>Current Month</h2>
            <p>
              ${(result.currentMonthBR * result.usdPrice).toFixed(2)} (
              {result.currentMonthBR.toFixed(2)})
            </p>
          </div>
          <div className="card first-card">
            <h5>
              {new Date(
                new Date().setMonth(new Date().getMonth() - 1)
              ).toLocaleString("default", { month: "long" })}
            </h5>
            <h2>Last Month</h2>
            <p>
              ${(result.lastMonthBR * result.usdPrice).toFixed(2)} (
              {result.lastMonthBR.toFixed(2)})
            </p>
          </div>
        </div>
        <div className="card-container">
          <div className="card quarter-width">
            <h5>{result.latestEntry.dailyAvg.io} io</h5>
            <h2>Daily Avg</h2>
            <p>${result.latestEntry.dailyAvg.usd}</p>
          </div>
          <div className="card quarter-width">
            <h5>{result.latestEntry.weekly.io} io</h5>
            <h2>Weekly</h2>
            <p>${result.latestEntry.weekly.usd}</p>
          </div>
          <div className="card quarter-width">
            <h5>{result.latestEntry.monthly.io} io</h5>
            <h2>Monthly</h2>
            <p>${result.latestEntry.monthly.usd}</p>
          </div>
          <div className="card quarter-width">
            <h5>{result.latestEntry.yearly.io} io</h5>
            <h2>Yearly</h2>
            <p>${result.latestEntry.yearly.usd}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ textAlign: "center", marginTop: "10%" }}>Loading...</div>
  );
};

export default App;
